.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: poppinsBold;
  src: url("../public/fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: poppinsExtraBold;
  src: url("../public/fonts/Poppins-ExtraBold.ttf");
}

@font-face {
  font-family: poppinsSemiBold;
  src: url("../public/fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: poppinsRegular;
  src: url("../public/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: poppinsLight;
  src: url("../public/fonts/Poppins-Light.ttf");
}

.poppinsBold {
  font-family: poppinsBold;
}

.poppinsExtraBold {
  font-family: poppinsExtraBold;
}

.poppinsSemiBold {
  font-family: poppinsSemiBold;
}

.poppinsRegular {
  font-family: poppinsRegular;
}

.poppinsLight {
  font-family: poppinsLight;
}
body{
  /* background-color: #EEF3FA; */
  background-color: #fcfcfd;
  margin: 0;
}
.resText{
  font-size: clamp(0.8rem, 2vw , 1rem);
}
.White-Color-Text {
  color: #FFFFFF;
  /* color: #000000; */
}
.Black-Color-Text {
  color: #212121;
  /* color: #000000; */
}
.Gradient-Dark {
  color: #8284D9;
}
.Gradient-Medium {
  color: #AF99BF;
}
.Gradient-Light {
  color: #E2B5A5;
}
.Yellow-Color-Text {
  color: #F9B708;
}
.Heading-Text {
  color: #4A556B;
}
.Detail-Text {
  color: #8B99B9;
}
.Gradient-Text {
	background: linear-gradient(to right, #8284D9 0%, #AF99BF 50%,#E2B5A5 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
  background-clip: calc();
}
.Gradient-Background {
  /* background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><linearGradient id='gradient'><stop offset='10%' stop-color='%23F00'/><stop offset='90%' stop-color='%23fcc'/> </linearGradient><rect fill='url(%23gradient)' x='0' y='0' width='100%' height='100%'/></svg>"); */
	background: linear-gradient(to bottom, #8284D9 0%, #AF99BF 50%,#E2B5A5 100%);
  width: 100%;
  height: auto;
}
.lightGradient-Background {
  background: linear-gradient(to bottom, 
  rgba(216, 189, 236, 0) 0%,    /* Fully transparent at the top */
  rgba(216, 189, 236, 0.3) 20%,   /* Start of solid color */
  rgba(216, 189, 236, 0.5) 50%,                  /* Middle solid color */
  rgba(216, 189, 236, 0.3) 80%,   /* End of solid color */
  rgba(216, 189, 236, 0) 100%  ); /* Fully transparent at the bottom */
  width: 100%;
  height: auto;
}
.parallelograph{
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 94%);
}
.parallelographTopBottom{
  clip-path: polygon(0 0, 100% 18%, 100% 100%, 0 84%);
}

.textCenter {
  text-align: "center";
}

.slick-slide > div {
  margin: 0 10px;
}
 
.slick-list {
  margin: 0 -10px;
}
 
.slick-slide *:focus{
  outline: none;
}

.blurEchoCard{
  width: 22rem;
  height: auto;
  margin-top: 12vh;
  border: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  text-align: center; 
  backdrop-filter: blur(10px);
  /* filter: blur(5px); */
} 
.blurEchoCard:hover{
  transform: scale(1.1, 1.1);
  /* transform: scale(0.9, 0.9);  */
}
.echoImage{
/* height: 14vh; */
/* width: 14vh; */
width: clamp(80px,25%,100px);
border-radius: 50%;
background-color: #f3def6;
position: absolute;
top: -45px;
left: 50%;
transform: translateX(-50%);
box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
object-fit: cover;
}

.stageCard{
  max-width: 22rem;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.1); 
  background: linear-gradient(
   135deg,
   rgba(255, 255, 255, 0.15) 0%,
   rgba(255, 255, 255, 0.05) 100%
 );
  transition: box-shadow 0.5s ease, border-radius 0.5s ease;
 box-shadow: 
 inset 0 -1.5em 2em rgba(130, 132, 217, 0.2),  
 inset 1.5em 0 2em rgba(175, 153, 191, 0.15),  
 inset -1.5em 0 2em rgba(226, 181, 165, 0.15);
 }
 .stageCard:hover {
   box-shadow: 
     inset 0 -1.5em 1.5em rgba(130, 132, 217, 0.05),
     inset 1.5em 0 2em rgba(175, 153, 191, 0.2),
     inset -1.5em 0 2em rgba(226, 181, 165, 0.4),
     0 0 1em rgba(0, 0, 0, 0.1);
   transform: scale(1.05, 1.05);
 }

.codepen-button {
  color: #212121;
  margin: 0 auto;
  position: relative;
  text-decoration: none;
  font-weight: 600;
  border-radius: 100px;
  overflow: hidden;
  padding: 3px;
  isolation: isolate;
  border-width: 0;
}
.codepen-button :hover {
  color: #8284D9;
}

.codepen-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 300%;
  height: 100%;
  background: linear-gradient(115deg,#8284D9,#E2B5A5,#AF99BF,#E2B5A5);
}
.codepen-button span {
  position: relative;
  display: block;
  padding: 0.7rem 1.2rem;
  font-size: 1rem;
  background: #EEF3FA;
  border-radius: 100px;
  height: 100%;
}
.polygon-card {
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
}
@media(min-width:1224px){
  .polygon-card{
    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
    padding: 10%;
  }
}
@media(max-width:475px){
  .polygon-card{
    border-width: 2px;
    border-color: #EEF3FA;
    border-style: solid;
    border-radius: 12px;
    padding: 5%;
    
  }
} 

.row-content-text{
  color: #b7b98b;
}
.faq-row  {
  border: 1px solid #AF99BF;
  border-radius: 8px; 
  margin-bottom: 15px;
}
.row-title {
  margin-left: 15px;
}



















